import styled from "styled-components";

export const ContainerBotaoSubmit = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding: 0.5rem;
    box-sizing: border-box;
`;

export const Input = styled.input`
`;