import React from "react";
import Layout from "../../structure/Layout";

const Responsaveis: React.FC = () => {
    return (
        <Layout>
            <h1>{"Responsaveis"}</h1>
        </Layout>
    );
};

export default Responsaveis;
